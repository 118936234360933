import { render, staticRenderFns } from "./referInfo.vue?vue&type=template&id=58de63bb&scoped=true&"
import script from "./referInfo.vue?vue&type=script&lang=js&"
export * from "./referInfo.vue?vue&type=script&lang=js&"
import style0 from "./referInfo.vue?vue&type=style&index=0&id=58de63bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58de63bb",
  null
  
)

export default component.exports