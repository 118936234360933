<template>
  <div class="referInfo">
    <!-- 测试 -->
    <el-form
      :model="referForm"
      :rules="referInfoRules"
      class="checkForm"
      label-position="top"
      ref="referRef"
    >
      <div class="cont_title">个人信息</div>
      <el-row :gutter="20">
        <!-- 姓名 -->
        <el-col :span="12" v-show="configJson.name == 1">
          <el-form-item
            :rules="
              configJson.name == 1 ? referInfoRules.name : [{ required: false }]
            "
            label="姓名"
            prop="name"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="填写真实姓名"
              ref="name"
              size="small"
              v-model="referForm.name"
            />
          </el-form-item>
        </el-col>
        <!-- 性别 -->
        <el-col :span="12" v-show="configJson.gender == 1">
          <el-form-item
            :rules="
              configJson.gender == 1
                ? referInfoRules.gender
                : [{ required: false }]
            "
            label="性别"
            prop="gender"
            style="height: 73px"
          >
            <el-radio-group
              ref="gender"
              size="small"
              v-model="referForm.gender"
            >
              <el-radio :label="0">男</el-radio>
              <el-radio :label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- 年龄 -->
        <el-col :span="12" v-show="configJson.age == 1">
          <el-form-item
            :rules="
              configJson.age == 1 ? referInfoRules.age : [{ required: false }]
            "
            label="年龄"
            prop="age"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="填写年龄"
              ref="age"
              size="small"
              v-model="referForm.age"
            />
          </el-form-item>
        </el-col>
        <!--国籍-->
        <el-col :span="12" v-show="configJson.country == 1">
          <el-form-item
            :rules="
              configJson.country == 1
                ? referInfoRules.country
                : [{ required: false }]
            "
            label="国籍"
            prop="country"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              filterable
              placeholder="国籍"
              v-model="referForm.country"
            >
              <el-option
                :key="index"
                :label="item.label"
                :value="item.label"
                v-for="(item, index) in countryList"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 入学年份 -->
        <!--<el-col :span="12">
          <el-form-item label="入学年份" prop="start_school_date">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-select
                  v-model="start_grade"
                  placeholder="年级"
                  :popper-append-to-body="false"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in gradeArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="24">
                <el-select
                  ref="start_school_date"
                  v-model="start_year"
                  placeholder="年份"
                  clearable
                  :popper-append-to-body="false"
                  @change="getyear()"
                >
                  <el-option
                    v-for="(item, index) in yearArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>-->
        <!-- 身份证号 -->
        <el-col :span="12" v-show="configJson.idCard == 1">
          <el-form-item
            :rules="
              configJson.idCard == 1
                ? referInfoRules.idCard
                : [{ required: false }]
            "
            label="身份证号"
            prop="id_card"
            style="height: 73px"
          >
            <el-input
              placeholder="填写真实身份证号"
              ref="id_card"
              size="small"
              v-model="referForm.id_card"
            />
          </el-form-item>
        </el-col>
        <!-- 手机号 -->
        <el-col :span="12" v-show="configJson.phone == 1">
          <el-form-item
            :rules="
              configJson.phone == 1
                ? referInfoRules.phone
                : [{ required: false }]
            "
            label="手机号"
            style="height: 73px"
          >
            <el-input
              :disabled="true"
              placeholder="请输入手机号"
              size="small"
              v-model="referForm.phone"
            />
          </el-form-item>
        </el-col>
        <!-- 学历 -->
        <el-col :span="12" v-show="configJson.education == 1">
          <el-form-item
            :rules="
              configJson.education == 1
                ? referInfoRules.education
                : [{ required: false }]
            "
            label="学历"
            prop="education"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              clearable
              placeholder="请选择学历"
              v-model="referForm.education"
            >
              <el-option
                :key="index"
                :label="item"
                :value="item"
                v-for="(item, index) in EducationArrs"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 单位名称 -->
        <el-col :span="12" v-show="configJson.unitandIdentity == 1">
          <el-form-item
            :rules="
              configJson.unitandIdentity == 1
                ? referInfoRules.unit_and_identity
                : [{ required: false }]
            "
            label="单位名称及身份"
            prop="unit_and_identity"
            style="height: 73px"
          >
            <el-input
              placeholder="请输入单位名称及身份"
              size="small"
              v-model="referForm.unit_and_identity"
            />
          </el-form-item>
        </el-col>
        <!-- 电子邮箱 -->
        <el-col :span="12" v-show="configJson.email == 1">
          <el-form-item
            :rules="
              configJson.email == 1
                ? referInfoRules.email
                : [{ required: false }]
            "
            label="电子邮箱"
            prop="email"
            style="height: 73px"
          >
            <el-input
              placeholder="填写电子邮箱"
              size="small"
              v-model="referForm.email"
            />
          </el-form-item>
        </el-col>
        <!-- 推荐人（非必填） -->
        <el-col :span="12" v-show="configJson.recommend == 1">
          <el-form-item
            label="什么渠道了解的该项目(或推荐人是)?"
            style="height: 73px"
          >
            <el-input
              placeholder="填写推荐人或渠道（选填）"
              size="small"
              v-model="referForm.recommend"
            />
          </el-form-item>
        </el-col>
        <!-- 出生日期 -->
        <el-col :span="12" v-show="configJson.birthday == 1">
          <el-form-item
            :rules="
              configJson.birthday == 1
                ? referInfoRules.birthday
                : [{ required: false }]
            "
            label="出生日期"
            prop="birthday"
            style="height: 73px"
          >
            <el-row :gutter="20">
              <el-col :span="8">
                <el-select
                  :popper-append-to-body="false"
                  placeholder="年"
                  v-model="bdYear"
                >
                  <el-option
                    :key="index"
                    :label="item"
                    :value="item"
                    v-for="(item, index) in yearArrs"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select
                  :popper-append-to-body="false"
                  placeholder="月"
                  v-model="bdMonth"
                >
                  <el-option
                    :key="index"
                    :label="item"
                    :value="item"
                    v-for="(item, index) in monthArrs"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select
                  :popper-append-to-body="false"
                  @change="getTime"
                  placeholder="日"
                  ref="birthday"
                  v-model="bdDay"
                >
                  <el-option
                    :key="index"
                    :label="item"
                    :value="item"
                    v-for="(item, index) in dayArrs"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <!-- 籍贯 -->
        <el-col :span="12" v-show="configJson.jiguanCity == 1">
          <el-form-item
            :rules="
              configJson.jiguanCity == 1
                ? referInfoRules.jiguan_code
                : [{ required: false }]
            "
            label="籍贯"
            prop="jiguan_code"
            style="height: 73px"
          >
            <el-row :gutter="20">
              <el-col :span="12">
                <!-- @keyup.enter.native="selsectProvince" -->
                <el-select
                  :popper-append-to-body="false"
                  @change="changeProvince(1, $event)"
                  placeholder="省"
                  v-model="jgProvinceCode"
                >
                  <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    v-for="item in selectProvince"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12">
                <el-select
                  :popper-append-to-body="false"
                  @change="getJiguan()"
                  placeholder="市"
                  ref="jiguan_code"
                  v-model="jiguan_city"
                >
                  <el-option
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    v-for="item in selectCityJiguan"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <!-- 民族 -->
        <el-col :span="12" v-show="configJson.nation == 1">
          <el-form-item
            :rules="
              configJson.nation == 1
                ? referInfoRules.nation
                : [{ required: false }]
            "
            label="民族"
            prop="nation"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="填写民族"
              ref="nation"
              size="small"
              v-model="referForm.nation"
            />
          </el-form-item>
        </el-col>
        <!-- 入学年份 -->
        <el-col :span="12" v-show="configJson.startSchoolDate == 1">
          <el-form-item
            :rules="
              configJson.startSchoolDate == 1
                ? referInfoRules.start_school_date
                : [{ required: false }]
            "
            label="入学年份"
            prop="start_school_date"
            style="height: 73px"
          >
            <el-row :gutter="20">
              <!-- <el-col :span="12">
                <el-select
                  v-model="start_grade"
                  placeholder="年级"
                  :popper-append-to-body="false"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in gradeArrs"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-col> -->
              <!-- <el-col :span="12"> -->
              <el-select
                :popper-append-to-body="false"
                @change="getyear()"
                clearable
                placeholder="年份"
                ref="start_school_date"
                v-model="start_year"
              >
                <el-option
                  :key="index"
                  :label="item"
                  :value="item"
                  v-for="(item, index) in yearArrs"
                >
                </el-option>
              </el-select>
              <!-- </el-col> -->
            </el-row>
          </el-form-item>
        </el-col>
        <!-- 政治面貌 -->
        <el-col :span="12" v-show="configJson.politicalStatus == 1">
          <el-form-item
            :rules="
              configJson.politicalStatus == 1
                ? referInfoRules.political_status
                : [{ required: false }]
            "
            label="政治面貌"
            prop="political_status"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              clearable
              placeholder="填写政治面貌"
              ref="political_status"
              v-model="referForm.political_status"
            >
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.name"
                v-for="item in political_status"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 在读年级 -->
        <el-col :span="12" v-show="configJson.grade == 1">
          <el-form-item
            :rules="
              configJson.grade == 1
                ? referInfoRules.grade
                : [{ required: false }]
            "
            label="在读年级"
            prop="grade"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              clearable
              placeholder="在读年级"
              v-model="referForm.grade"
            >
              <el-option
                :key="index"
                :label="item"
                :value="item"
                v-for="(item, index) in gradeArrs"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 选科情况 -->
        <el-col :span="12" v-show="configJson.electSubjectSituation == 1">
          <el-form-item
            :rules="
              configJson.electSubjectSituation == 1
                ? referInfoRules.subjectState
                : [{ required: false }]
            "
            label="选科情况"
            prop="subjectState"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              @change="changeSubjectState"
              clearable
              placeholder="请选科"
              v-model="referForm.subjectState"
            >
              <el-option
                :key="index"
                :label="item"
                :value="item"
                v-for="(item, index) in electSubjectArrs"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--已选科-->
        <el-col
          :span="12"
          v-if="configJson.electSubjectSituation == 1 && showSubject == 1"
        >
          <el-form-item
            :rules="
              showSubject == 1
                ? referInfoRules.chooseSubject
                : [{ required: false }]
            "
            label="选科(最多选三科)"
            prop="chooseSubject"
            style="height: 73px"
          >
            <el-select
              :multiple-limit="3"
              :popper-append-to-body="false"
              @change="changeSubjectList"
              clearable
              multiple
              placeholder="请选科"
              v-model="referForm.chooseSubject"
            >
              <el-option
                :key="index"
                :label="item"
                :value="item"
                v-for="(item, index) in SubjectArrs"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 既往病史或过敏史 -->
        <el-col :span="12" v-show="configJson.pastMedicalHistory == 1">
          <el-form-item
            :rules="
              (configJson.pastMedicalHistory == 1 ? true : false) &&
              isOffline == true
                ? referInfoRules.pastMedicalHistory
                : [{ required: false }]
            "
            label="既往病史或过敏史"
            prop="past_medical_history"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="填写既往病史或过敏史"
              ref="code"
              size="small"
              v-model="referForm.past_medical_history"
            />
          </el-form-item>
        </el-col>
        <!-- 备注或其它 -->
        <el-col :span="12" v-show="configJson.noteOther == 1">
          <el-form-item
            :rules="
              (configJson.noteOther == 1 ? true : false) && isOffline == true
                ? referInfoRules.noteOther
                : [{ required: false }]
            "
            label="备注或其它"
            prop="note_other"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="填写备注或其它"
              ref="code"
              size="small"
              v-model="referForm.note_other"
            />
          </el-form-item>
        </el-col>
        <!-- 衣服尺码-->
        <el-col :span="12" v-show="configJson.clothesSize == 1">
          <el-form-item
            :rules="
              (configJson.clothesSize == 1 ? true : false) && isOffline == true
                ? referInfoRules.clothesSize
                : [{ required: false }]
            "
            label="衣服尺码"
            prop="clothes_size"
            style="height: 73px"
          >
            <el-select
              :popper-append-to-body="false"
              clearable
              placeholder="填写衣服尺码"
              ref="clothes_size"
              v-model="referForm.clothes_size"
            >
              <el-option
                :key="item.id"
                :label="item.name"
                :value="item.name"
                v-for="item in clothesSize"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 邀请码 -->
        <el-col :span="12" v-show="configJson.code == 1">
          <el-form-item label="邀请码" prop="code" style="height: 73px">
            <el-input
              :disabled="isCode"
              clearable
              placeholder="邀请码"
              ref="code"
              size="small"
              v-model="referForm.code"
            />
          </el-form-item>
          <div class="tipsMsg">每个邀请码仅能绑定一个用户</div>
        </el-col>
        <!--  自我介绍      -->
        <el-col :span="12" v-show="configJson.introduceMyself == 1">
          <el-form-item
            :rules="
              configJson.introduceMyself == 1
                ? referInfoRules.introduceMyself
                : [{ required: false }]
            "
            label="自我介绍"
            prop="introduce"
            style="height: 100%"
          >
            <el-input
              :rows="6"
              @change="changeIntroduce"
              maxlength="200"
              placeholder="自我介绍"
              ref="code"
              show-word-limit
              size="small"
              type="textarea"
              v-model="referForm.introduce"
            />
          </el-form-item>
          <div class="tipsMsg">
            用清晰、简洁的语言介绍自己对科学和创新的兴趣、经验和动机，以及为什么希望参加“强国有我”复旦大学信息科学与工程学院青少年科学营
          </div>
        </el-col>
        <!--  班级   -->
        <el-col :span="12" v-show="configJson.class == 1">
          <el-form-item
            :rules="
              configJson.class == 1
                ? referInfoRules.class
                : [{ required: false }]
            "
            label="班级"
            prop="class"
            style="height: 73px"
          >
            <el-input
              clearable
              placeholder="班级"
              ref="class"
              size="small"
              v-model="referForm.class"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!--  紧急联系人-->
      <div v-show="configJson.emergency == 1 || configJson.emergencyPhone == 1">
        <div class="cont_title">紧急联系人</div>
        <el-row :gutter="20">
          <!-- 紧急联系人 -->
          <el-col :span="12" v-show="configJson.emergency == 1">
            <el-form-item
              :rules="
                configJson.emergency == 1
                  ? referInfoRules.emergency
                  : [{ required: false }]
              "
              label="紧急联系人"
              prop="emergency"
            >
              <el-input
                clearable
                placeholder="填写紧急联系人"
                ref="code"
                size="small"
                v-model="referForm.emergency"
              />
            </el-form-item>
          </el-col>
          <!-- 紧急联系人电话 -->
          <el-col :span="12" v-show="configJson.emergencyPhone == 1">
            <el-form-item
              :rules="
                configJson.emergencyPhone == 1
                  ? referInfoRules.emergency_phone
                  : [{ required: false }]
              "
              label="紧急联系人电话"
              prop="emergency_phone"
            >
              <el-input
                clearable
                placeholder="填写紧急联系人电话"
                ref="code"
                size="small"
                v-model="referForm.emergency_phone"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 学校信息 -->
      <div
        v-show="
          configJson.school == 1 ||
          configJson.schoolContacts == 1 ||
          configJson.schoolPhone == 1
        "
      >
        <div class="cont_title">学校信息</div>
        <div class="school">
          <el-row :gutter="20">
            <div class="school_info" v-show="configJson.school == 1">
              <el-col :span="6">
                <el-form-item
                  :rules="
                    configJson.school == 1
                      ? referInfoRules.school_province
                      : [{ required: false }]
                  "
                  label="就读中学"
                  prop="school_province"
                >
                  <el-select
                    :popper-append-to-body="false"
                    @change="changeProvince(2, $event)"
                    clearable
                    placeholder="省"
                    ref="school_province"
                    v-model="referForm.school_province"
                  >
                    <el-option
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      v-for="item in selectProvince"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  :rules="
                    configJson.school == 1
                      ? referInfoRules.school_city
                      : [{ required: false }]
                  "
                  class="no_label"
                  label=" "
                  prop="school_city"
                >
                  <el-select
                    :popper-append-to-body="false"
                    @change="changeCity($event)"
                    clearable
                    placeholder="市"
                    ref="school_city"
                    v-model="referForm.school_city"
                  >
                    <el-option
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      v-for="item in selectCitySchool"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  :rules="
                    configJson.school == 1
                      ? referInfoRules.school
                      : [{ required: false }]
                  "
                  class="no_label"
                  label=" "
                  prop="school"
                >
                  <el-select
                    :popper-append-to-body="false"
                    clearable
                    filterable
                    placeholder="中学"
                    ref="school"
                    v-model="referForm.school"
                  >
                    <el-option
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      v-for="item in selectSchoolList"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="tipsMsg" v-show="$store.state.baseSchool == 1">
                  如果没有您所在的学校，请选填其他
                </div>
                <div class="tipsMsg" v-show="$store.state.baseSchool == 0">
                  如果没有您所在学校，请联系邮箱：service@campcenter.cn
                </div>
              </el-col>
            </div>

            <el-col :span="12" v-show="configJson.schoolContacts == 1">
              <el-form-item
                :rules="
                  configJson.schoolContacts == 1
                    ? referInfoRules.school_contacts
                    : [{ required: false }]
                "
                label="中学联系人"
                prop="school_contacts"
              >
                <el-input
                  clearable
                  placeholder="填写中学联系人"
                  ref="school_contacts"
                  size="small"
                  v-model="referForm.school_contacts"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="configJson.schoolPhone == 1">
              <el-form-item
                :rules="
                  configJson.schoolPhone == 1
                    ? referInfoRules.school_phone
                    : [{ required: false }]
                "
                label="中学联系人电话（手机）"
                prop="school_phone"
              >
                <el-input
                  clearable
                  placeholder="填写中学联系人电话（手机）"
                  ref="school_phone"
                  size="small"
                  v-model="referForm.school_phone"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 学习成绩证明材料 -->
      <div v-show="configJson.achievementPath == 1">
        <div class="cont_title">学习成绩证明材料</div>
        <el-form-item
          class="bgPic"
          label="上传申请资料图片"
          prop="backgroundPicture"
          style="height: 100%"
        >
          <div class="bgInfo">
            1、资料包括：近期校级考试的成绩单、在校期间获得证书等相关证明；
          </div>
          <div class="bgInfo">
            2、请上传1-10张图片，支持JPG、JPEG、PNG等格式，每张图片不超过10M
          </div>
          <div class="image_add">
            <div class="image_in">
              <el-upload
                :before-upload="beforeAvatarUpload"
                :class="{ hide: hideUpload }"
                :disabled="isUploading || fileList.length >= 10"
                :file-list="fileList"
                :http-request="uploadPicture"
                :limit="10"
                :on-success="uploadSuccess"
                accept=".jpg,.jpeg,.png"
                action="#"
                list-type="picture-card"
                name="multipartFile"
                ref="imgUrl"
              >
                <i class="el-icon-circle-plus" style="font-size: 44px"> </i>
                <!-- <div class="text">上传材料</div> -->
                <div slot="file" slot-scope="{ file }">
                  <div :key="idx" v-for="(item, idx) in fileList">
                    <img
                      :src="file.url"
                      alt=""
                      class="el-upload-list__item-thumbnail"
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        @click="handlePictureCardPreview(file)"
                        class="el-upload-list__item-preview"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        @click="handleDownload(file)"
                        class="el-upload-list__item-delete"
                        v-if="!disabled"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                      <span
                        @click="handleRemove(file)"
                        class="el-upload-list__item-delete"
                        v-if="!disabled"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </el-upload>
            </div>

            <el-dialog :visible.sync="dialogVisible">
              <img :src="resultPicture" alt="" width="100%" />
            </el-dialog>
          </div>
        </el-form-item>
      </div>

      <div class="btn">
        <el-button @click="confirm">提交资料</el-button>
      </div>
    </el-form>
    <!--    多形式报名邀请码报错弹框   -->
    <cueDialog :dialogVisible="cueDialogVisible" :msg="msg"></cueDialog>
  </div>
</template>

<script>
/* eslint-disable */
import {
  delPic,
  getAccount,
  getProAndCity,
  getSchool,
  setAccount,
} from "r/index/signUp";
import COS from "cos-js-sdk-v5";
import { achievementCallBack, cosSignature } from "r/index/cosUpload";
import validator from "common/validator";
import area from "config/area";
import cueDialog from "c/index/cueDialog";

export default {
  name: "referInfo",
  props: { invitation_code: String },
  components: { cueDialog },
  data() {
    const validateContent = (rule, value, callback) => {
      if (!this.referForm.birthday) {
        callback(new Error("出生日期不能为空！"));
      } else {
        callback();
      }
    };
    const validateContents = (rule, value, callback) => {
      if (!this.referForm.jiguan_code) {
        callback(new Error("籍贯不能为空！"));
      } else {
        callback();
      }
    };
    const validateSubject = (rule, value, callback) => {
      if (!this.elect_subject_situation) {
        callback(new Error("选科不能为空！"));
      } else {
        callback();
      }
    };
    return {
      cueDialogVisible: false,
      msg: null,
      configJson: {},
      isUploading: false, // 图片上传蒙层
      isCode: false, // 邀请码是否禁用
      showSubject: 0, //是否展示选科（多选）
      subjectState: "",
      elect_subject_situation: null,
      referForm: {
        name: null,
        gender: null,
        age: null,
        id_card: null,
        education: null,
        birthday: null,
        jiguan_code: null,
        address: null,
        country: null,
        nation: null,
        start_school_date: null,
        political_status: null,
        phone: null,
        grade: null,
        unit_and_identity: null,
        email: null,
        recommend: null,
        subjectState: null,
        chooseSubject: [],
        past_medical_history: null,
        note_other: null,
        clothes_size: null,
        class: null,
        emergency: null,
        emergency_phone: null,
        introduce: null,
        school_province: null,
        school_city: null,
        school: null,
        school_contacts: null,
        school_phone: null,
        code: null,
      },
      // doubt_code: window.localStorage.getItem(
      //   "doubt_code" + this.$store.state.indexIdentity
      // ),//是否填写邀请码
      schoolName: {},
      referInfoRules: {
        name: [{ required: true, message: "姓名不能为空！", trigger: "blur" }],
        gender: [
          { required: true, message: "性别不能为空！", trigger: "change" },
        ],
        id_card: [
          // { required: true, message: "身份证号不能为空！", trigger: "blur" },
          { validator: validator.validateid_card, trigger: "blur" },
        ],
        birthday: [
          {
            required: true,
            validator: validateContent,
            trigger: "change",
          },
        ],
        jiguan_code: [
          { required: true, validator: validateContents, trigger: "change" },
        ],

        nation: [
          { required: true, message: "民族不能为空！", trigger: "change" },
        ],
        country: [
          {
            required: true,
            message: "国籍不能为空！",
            trigger: "change",
          },
        ],
        start_school_date: [
          {
            required: true,
            message: "入学年份不能为空！",
            trigger: "change",
          },
        ],
        political_status: [
          { required: true, message: "政治面貌不能为空！", trigger: "blur" },
        ],
        email: [
          { required: true, message: "电子邮箱不能为空！", trigger: "blur" },
          { validator: validator.validateEmail, trigger: "blur" },
        ],
        grade: [
          { required: true, message: "在读年级不能为空！", trigger: "blur" },
        ],
        age: [
          { required: true, message: "年龄不能为空！", trigger: "blur" },
          { validator: validator.validateNumber, trigger: "blur" },
        ],
        education: [
          { required: true, message: "学历不能为空！", trigger: "change" },
        ],
        idCard: [
          {
            required: true,
            message: "身份证号不能为空！",
            trigger: "blur",
          },
          { validator: validator.validateid_card, trigger: "blur" },
        ],
        // address: [
        //   { required: true, message: "详细地址不能为空！", trigger: "blur" },
        // ],
        unit_and_identity: [
          {
            required: true,
            message: "单位名称及身份不能为空！",
            trigger: "blur",
          },
        ],
        subjectState: [
          {
            required: true,
            message: "选科情况不能为空！",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "手机号不能为空！",
            trigger: "blur",
          },
          { validator: validator.validatePhone, trigger: "blur" },
        ],
        chooseSubject: [
          {
            required: true,
            validator: validateSubject,
            trigger: "change",
          },
        ],
        past_medical_history: [
          {
            required: true,
            message: "既往病史或过敏史不能为空！",
            trigger: "blur",
          },
        ],
        note_other: [
          {
            required: true,
            message: "备注或其它不能为空！",
            trigger: "blur",
          },
        ],
        class: [
          {
            required: true,
            message: "班级不能为空！",
            trigger: "blur",
          },
        ],
        emergency: [
          {
            required: true,
            message: "紧急联系人不能为空！",
            trigger: "blur",
          },
        ],
        emergency_phone: [
          {
            required: true,
            message: "紧急联系人电话不能为空！",
            trigger: "blur",
          },
          { validator: validator.validatePhone, trigger: "blur" },
        ],
        clothesSize: [
          {
            required: true,
            message: "衣服尺码不能为空！",
            trigger: "change",
          },
        ],
        introduce: [
          {
            required: true,
            message: "自我介绍不能为空！",
            trigger: "blur",
          },
        ],
        school_province: [
          {
            required: true,
            message: "请选择所在学校省份！",
            trigger: "change",
          },
        ],
        school_city: [
          {
            required: true,
            message: "请选择所在学校城市！",
            trigger: "change",
          },
        ],
        school: [
          { required: true, message: "请选择所在学校！", trigger: "change" },
        ],
        school_contacts: [
          { required: true, message: "请填写中学联系人！", trigger: "blur" },
        ],
        school_phone: [
          {
            required: true,
            message: "中学联系人电话（手机）不能为空！",
            trigger: "blur",
          },
          { validator: validator.validatePhone, trigger: "blur" },
        ],
        // backgroundPicture: [{ required: true, message: "请上传图片" }],
      },
      countryList: area.country, //国籍
      selectProvince: [], //省份
      CityName: {}, //根据省份筛选出所有城市的对象
      selectCityJiguan: [], //籍贯城市列表
      selectCitySchool: [], //就读中学-城市列表
      selectSchoolList: [], //就读中学-中学列表
      jgProvinceCode: "", //省份id
      jiguan_city: "", //城市id(和省份id拼接)
      electSubjectArrs: ["未选科", "已选科", "文科", "理科"],
      SubjectArrs: ["物理", "历史", "化学", "地理", "生物", "政治"],
      EducationArrs: ["初中", "高中", "大学", "硕士", "博士"],
      yearArrs: [], //生日涉及的近10年的年份
      monthArrs: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      dayArrs: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      bdYear: "", //选择的生日年份
      bdMonth: "", //选择的生日月份
      bdDay: "", //选择的生日日期
      //入学年份（初中高中）
      gradeArrs: ["初一", "初二", "初三", "高一", "高二", "高三"],
      //选择年级
      start_grade: "",
      start_year: "",
      // 政治面貌
      political_status: [
        {
          id: 1,
          name: "中共党员",
        },
        {
          id: 2,
          name: "中共预备党员",
        },
        {
          id: 3,
          name: "共青团员",
        },
        {
          id: 4,
          name: "民革党员",
        },
        {
          id: 5,
          name: "民盟盟员",
        },
        {
          id: 6,
          name: "民建会员",
        },
        {
          id: 7,
          name: "民进会员",
        },
        {
          id: 8,
          name: "农工党党员",
        },

        {
          id: 9,
          name: "致公党党员",
        },
        {
          id: 10,
          name: "九三学社社员",
        },
        {
          id: 11,
          name: "台盟盟员",
        },
        {
          id: 12,
          name: "无党派人士",
        },
        {
          id: 13,
          name: "群众",
        },
      ],
      //衣服尺码
      clothesSize: [
        {
          id: 1,
          name: "S",
        },
        {
          id: 2,
          name: "M",
        },
        {
          id: 3,
          name: "L",
        },
        {
          id: 4,
          name: "XL",
        },
        {
          id: 5,
          name: "XXL",
        },
        {
          id: 6,
          name: "XXXL",
        },
        {
          id: 7,
          name: "XXXXL",
        },
      ],
      // 中学（模糊搜索）
      // timeout: null,
      // school: "",
      // 上传的图片
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
      resultPicture: "",
      disabled: false, //限制输入框
      isOffline: false, //活动形式是否包含线下字段
      hideUpload: false,
    };
  },
  created() {
    const code = this.$store.state.indexIdentity;
    this.referForm.phone = window.localStorage.getItem("index-phone-all"); //本地取用户手机号
    let signUpFile = window.localStorage.getItem(
      "sign_up_file_" + window.localStorage.getItem("index-phone-all") + code
    );
    // console.log(signUpFile);
    if (signUpFile && JSON.parse(signUpFile).length > 0) {
      this.fileList = JSON.parse(signUpFile);
      this.hideUpload = this.fileList.length >= 10;
    }
    // this.getSchool();
    this.getTenYear();
    this.getAccount(); // 页面信息个人获取
    this.configJson = this.$store.state.configJson;
    if (this.configJson.jiguanCity == 1 || this.configJson.school == 1) {
      this.getProAndCity();
    }
    //若线下形式(既往病史或过敏史，备注或其它，衣服尺码)为必填项，否则为选填。
    let multiform = JSON.parse(window.localStorage.getItem("multiform" + code));
    let multiform_id = window.localStorage.getItem("multiform_id" + code);
    if (multiform != "[]") {
      multiform.forEach((i) => {
        if (i.id == multiform_id) {
          let reg = /[\u7ebf][\u4e0b]/;
          this.isOffline = reg.test(i.multiform_name);
        }
      });
    }
    console.log(this.isOffline);
  },
  watch: {
    referForm(val) {
      for (let k in val) {
        if (val[k] == null || !val[k]) {
          val[k] = this.referForm[k];
        }
      }
      this.selfInfo = val;
      if (val.gender && val.gender !== "") {
        // console.log(val.gender)
        this.referForm.gender = val.gender;
      }
      //出生日期
      if (val.birthday && val.birthday !== "") {
        this.bdYear = val.birthday.slice(0, 4); //前4位取年
        this.bdMonth = val.birthday.slice(4, 6); //中2位取月
        this.bdDay = val.birthday.slice(6, 8); //后2位取日
      }
      //入学年份
      if (val.start_school_date && val.start_school_date !== "") {
        this.start_year = val.start_school_date.slice(0, 4); //年份
        this.start_grade = val.start_school_date.slice(5, 8); //年级
      }
      //籍贯
      if (
        val.jiguan_city !== null &&
        val.jiguan_province !== null &&
        this.configJson.jiguanCity == 1
      ) {
        if (this.referForm.jiguan_city) {
        }
        this.jgProvinceCode = Number(val.jiguan_province);
        this.changeProvince(1, this.jgProvinceCode, true);
        this.jiguan_city = Number(val.jiguan_city);
      }
      //学校
      if (
        val.school_province !== null &&
        val.school_city !== null &&
        val.school !== null &&
        this.configJson.school == 1
      ) {
        this.referForm.school_province = Number(val.school_province);
        this.referForm.school_city = Number(val.school_city);
        this.referForm.school = Number(val.school);
        this.changeProvince(2, this.referForm.school_province, true);
        this.changeCity(this.referForm.school_city, true);
      }

      if (
        val.elect_subject_situation == "文科" ||
        val.elect_subject_situation == "理科"
      ) {
        this.referForm.subjectState = val.elect_subject_situation;
        this.showSubject = 0;
      } else if (
        val.elect_subject_situation != null &&
        val.elect_subject_situation !== "" &&
        val.elect_subject_situation !== "-"
      ) {
        this.referForm.subjectState = "已选科";
        this.showSubject = 1;
        this.referForm.chooseSubject = val.elect_subject_situation.split("#");
        this.elect_subject_situation = val.elect_subject_situation;
      } else {
        this.referForm.subjectState = "未选科";
        this.showSubject = 0;
        this.elect_subject_situation = "-";
        this.referForm.chooseSubject = [];
      }
      if (this.invitation_code != null) {
        this.referForm.code = this.invitation_code;
        this.isCode = true;
      }
      //自我介绍
      let introduce = this.$store.state.signUpInfo.introduce;
      if (introduce != null) {
        this.referForm.introduce = introduce;
      }
    },
  },
  mounted() {},
  methods: {
    // 页面信息个人获取
    getAccount() {
      getAccount({}).then((res) => {
        // console.log(res, "getAccount");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        let info = res.data.info;
        info["code"] = null;
        let referForm = {
          name: null,
          gender: null,
          age: null,
          id_card: null,
          education: null,
          birthday: null,
          jiguan_code: null,
          address: null,
          country: null,
          nation: null,
          start_school_date: null,
          political_status: null,
          phone: null,
          grade: null,
          unit_and_identity: null,
          email: null,
          recommend: null,
          subjectState: null,
          chooseSubject: [],
          past_medical_history: null,
          note_other: null,
          clothes_size: null,
          class: null,
          emergency: null,
          emergency_phone: null,
          introduce: null,
          school_province: null,
          school_city: null,
          school: null,
          school_contacts: null,
          school_phone: null,
          code: null,
        };
        for (let k in info) {
          referForm[k] = info[k];
        }
        this.referForm = referForm;
        if (res.data.info.jiguan_province && res.data.info.jiguan_city) {
          this.referForm.jiguan_code =
            res.data.info.jiguan_province.toString() +
            res.data.info.jiguan_city.toString();
        }
        console.log(typeof window.localStorage.getItem("index-email-all"));
        if (res.data.info.email == null) {
          let email = window.localStorage.getItem("index-email-all");
          if (email == 'null') {
            this.referForm.email = null
          } else {
            this.referForm.email =
              window.localStorage.getItem("index-email-all"); //本地取用户邮箱
          }
        }
      });
    },
    //获取近10年的年份
    getTenYear() {
      let nowDate = new Date();
      let currentYear = nowDate.getFullYear();
      // console.log(nowDate, currentYear);
      let yearArr = [];
      let rxYearArr = [];
      for (let i = 0; i < 73; i++) {
        let year = currentYear - i;
        yearArr.push(year);
      }
      // console.log(yearArr, "yearArr");
      this.yearArrs = yearArr;
    },
    //组装出生日期
    getTime() {
      this.referForm.birthday =
        this.bdYear.toString() +
        this.bdMonth.toString() +
        this.bdDay.toString();
    },
    // 国籍
    //组装入学年份
    getyear() {
      this.referForm.start_school_date = this.start_year.toString();
      // + "-" +
      // this.start_grade.toString();
    },
    // 选科情况
    changeSubjectState(val) {
      // this.referForm.subjectState = val
      if (val == "已选科") {
        this.referForm.subjectState = val;
        this.showSubject = 1;
        this.elect_subject_situation = "";
        // console.log(this.elect_subject_situation)
      } else if (val == "未选科") {
        this.showSubject = 0;
        this.referForm.subjectState = val;
        this.elect_subject_situation = "-";
        // console.log(this.referForm.subjectState)
      } else {
        //文科理科
        this.elect_subject_situation = val;
        this.showSubject = 0;
      }
    },
    changeSubjectList(val) {
      this.referForm.chooseSubject = val;
      this.elect_subject_situation = val.join("#");
      this.$forceUpdate();
    },
    //自我介绍
    changeIntroduce(val) {
      this.referForm.introduce = val;
    },
    // 获取省份城市
    getProAndCity() {
      getProAndCity({}).then((res) => {
        // console.log(res, "getProAndCity");
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        this.selectProvince = res.data.province_list;
      });
    },
    //选择籍贯省份change事件
    changeProvince(type, val, status) {
      // console.log(type, val);
      if (type == 1) {
        this.selectCityJiguan = [];
        this.selectCityJiguan = this.getCurrentCityLists(val);
        if (!status) {
          this.jiguan_city = "";
          this.referForm.jiguan_code = "";
        }
      } else {
        this.selectCitySchool = [];
        this.selectCitySchool = this.getCurrentCityLists(val); //在此调用城市筛选列表
        if (!status) {
          this.referForm.school_city = "";
          this.referForm.school = "";
          this.selectSchoolList = []; //重新选择省份时候，学校list清空
        }
      }
    },
    //返回当前省份下的城市列表
    getCurrentCityLists(val) {
      let currentCity = [];
      if (
        this.CityName == null ||
        !this.CityName ||
        Object.values(this.CityName).length == 0
      ) {
        getProAndCity({})
          .then((res) => {
            // console.log(res, "getProAndCity");
            if (res.data.code === 200) {
              // this.selectProvince = res.data.province_list;
              this.CityName = res.data.city_list;
              for (let key in this.CityName) {
                if (this.CityName[key].parent_id == val) {
                  currentCity.push(this.CityName[key]);
                }
              }
              return currentCity;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        for (let key in this.CityName) {
          if (this.CityName[key].parent_id == val) {
            currentCity.push(this.CityName[key]);
          }
        }
        return currentCity;
      }
      return currentCity;
    },
    // 籍贯城市change事件
    getJiguan() {
      this.referForm.jiguan_code =
        this.jgProvinceCode.toString() + this.jiguan_city.toString();
    },
    // getSchool() {
    //   console.log('getSchool')
    //   getSchool({}).then((res) => {
    //     // console.log(res, "getSchool");
    //     if (res.data.code != 200) {
    //       return this.$message.error(res.data.msg);
    //     }
    //     this.schoolName = res.data.school_list;
    //   });
    // },
    // 学校城市change事件
    changeCity(val, status) {
      // console.log(12345333,val,status)
      this.selectSchoolList = [];
      this.selectSchoolList = this.getCurrentSchoolLists(val);
      if (!status) {
        this.referForm.school = "";
      }
      // let data = this.selectSchoolList.map((item) => {
      //   return {
      //     name: item.id,
      //     value: item.name,
      //   };
      // });
      // this.restaurants = data; //中学（模糊搜索）
    },
    // 返回当前城市下的学校列表
    getCurrentSchoolLists(val) {
      let currentSchool = [];
      let baseSchool = this.$store.state.baseSchool;
      if (baseSchool && baseSchool == 1) {
        currentSchool.unshift({
          id: 0,
          name: "其他",
        });
      }
      // console.log(this.schoolName);
      if (
        this.schoolName == null ||
        !this.schoolName ||
        Object.values(this.schoolName).length == 0
      ) {
        getSchool({})
          .then((res) => {
            // console.log(res)
            if (res.data.code === 200) {
              this.schoolName = res.data.school_list;
              //  console.log( this.schoolName)
              let code = this.$store.state.indexIdentity;
              window.localStorage.setItem(
                "schoolName" + code,
                JSON.stringify(res.data.list)
              );
              for (let key in this.schoolName) {
                if (this.schoolName[key].city_id == val) {
                  currentSchool.push(this.schoolName[key]);
                }
              }
              return currentSchool;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        // console.log(this.schoolName, 111);
        for (let key in this.schoolName) {
          // console.log(key, this.schoolName[key]);
          if (this.schoolName[key].city_id == val) {
            currentSchool.push(this.schoolName[key]);
          }
        }
        // console.log(currentSchool, 222);
        return currentSchool;
      }
      return currentSchool;
    },
    // 图片上传成功前钩子
    beforeAvatarUpload(file) {
      // console.log(file, "beforeImage");
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJPEG = file.type === "image/jpeg";
      const isLt10M = file.size / 1024 / 1024 < 10;
      //  console.log(isLt10M)
      if (!isJPG && !isPng && !isJPEG) {
        this.$message.info("上传图片只能是 JPG,JPEG或png 格式!");
      }
      if (!isLt10M) {
        this.$message.info("上传图片大小不能超过 10MB!");
      }
      return (isJPG || isPng || isJPEG) && isLt10M;
    },
    handlePictureCardPreview(file) {
      this.resultPicture = file.url;
      this.dialogVisible = true;
    },
    uploadSuccess(res, file, fileList) {
      this.referForm.achievementPath = res;
      this.fileList = {
        name: file.name,
        url: res,
      };
      this.$refs["imgUrl"].clearFiles(); //上传成功之后清除历史记录
    },
    uploadPicture(params) {
      // console.log("已经准备好上传图片", params);
      this.file = params.file;
      let raw = params.file;
      let image = {
        name: raw.name,
        size: raw.size,
        type: raw.type,
      };
      this.getCosConfigs(image, params.file);
    },
    ChangeImage(fileList) {
      this.hideUpload = fileList.length >= 10;
    },
    // cos上传
    async getCosConfigs(image, file) {
      console.log(file);
      this.loading = true;
      await cosSignature({ image: image, index: "1" })
        .then((res) => {
          console.log(res, "开始上传图片");
          let resData = res.data.data;
          if (res.data.code === 200) {
            const cos = new COS({
              // 必选参数
              getAuthorization: (options, callback) => {
                callback({
                  TmpSecretId:
                    resData.credentials.credential.credentials.tmpSecretId,
                  TmpSecretKey:
                    resData.credentials.credential.credentials.tmpSecretKey,
                  XCosSecurityToken:
                    resData.credentials.credential.credentials.sessionToken,
                  StartTime: resData.credentials.credential.startTime, // 时间戳，单位秒，如：1580000000
                  ExpiredTime: resData.credentials.credential.expiredTime, // 时间戳，单位秒，如：1580000900
                });
              },
            });
            console.log(resData);
            cos.putObject(
              {
                Bucket: resData.credentials.bucket,
                Region: resData.credentials.region,
                Key: resData.key,
                Body: file,
                onProgress: (progressData) => {
                  console.log("上传进度：" + JSON.stringify(progressData));
                },
              },
              (err, data) => {
                console.log(data);
                if (err) {
                  console.log(err);
                  this.loading = false;
                  this.$message.error("上传失败！");
                } else {
                  console.log(data);
                  this.signUploadSuccess("http://" + data.Location, resData);
                }
              }
            );
          } else {
            this.$message.error(resData.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.getIdCardPic();
        });
    },
    // cos上传-告诉后端上传成功
    async signUploadSuccess(path, resData) {
      await achievementCallBack({ key: resData.key, index: 1 })
        .then((res) => {
          if (res.data.code != 200) {
            this.loading = false;
            return this.$message.error("上传失败！");
          }
          this.loading = false;
          this.$message.success("上传成功！");
          this.resultPicture = path + "?t=" + Date.parse(new Date());
          this.$forceUpdate();
          let obj = {
            url: res.data.picture_path,
          };
          this.fileList.push(obj);
          let code = this.$store.state.indexIdentity;
          window.localStorage.setItem(
            "sign_up_file_" +
              +window.localStorage.getItem("index-phone-all") +
              code,
            JSON.stringify(this.fileList)
          );
          this.ChangeImage(this.fileList);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // async getOssConfigs(image, file) {
    //   let obj = {
    //     url: "",
    //     savefile: "",
    //   };
    //   await getAchievementOss({image: image})
    //     .then((res) => {
    //       // console.log(res, "开始上传图片");
    //       if (res.data.code === 200) {
    //         let oss_url = res.data.config.host;
    //         let filename = res.data.config.file;
    //         let formData = new FormData();
    //         formData.append("OSSAccessKeyId", res.data.config.accessid);
    //         formData.append("policy", res.data.config.policy);
    //         formData.append("signature", res.data.config.signature);
    //         formData.append("filename", res.data.config.file);
    //         formData.append("key", res.data.config.savefile);
    //         formData.append("callback", res.data.config.callback);
    //         formData.append("success_action_status", 200);
    //         formData.append("file", file);
    //         // console.log(formData);
    //         upload(oss_url, formData)
    //           .then((ress) => {
    //             // console.log(ress, "ress");
    //             if (ress.data.code != 200) {
    //               return this.$message.error(ress.data.msg);
    //             }
    //             this.resultPicture = filename + "?t=" + Date.parse(new Date());
    //             this.$forceUpdate();
    //             this.$message.success("上传成功");
    //             obj = {
    //               url: ress.data.picture_path,
    //               savefile: res.data.config.savefile,
    //             };
    //             this.fileList.push(obj);
    //             let code = this.$store.state.indexIdentity;
    //             window.localStorage.setItem("sign_up_file_" +
    //               +window.localStorage.getItem("index-phone-all") +
    //               code,
    //               JSON.stringify(this.fileList)
    //             );
    //           })
    //           .catch((err) => {
    //             this.$message.error(err);
    //           });
    //       } else {
    //         this.$message.error(res.data.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       // console.log(err);
    //     });
    // },
    // 下载证明资料图片
    handleDownload(file) {
      // console.log(file,'file22');
      window.location.href = file.url;
    },
    //删除图片
    handleRemove(file) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let getfile = file.url;
          let str = getfile.split(
            "https://yingdi-achievement-1320656968.cos.ap-beijing.myqcloud.com/"
          ); //https://yingdi-achievement-1320656968.cos.ap-beijing.myqcloud.com/进行分割，
          let savefile = str[1].split("?")[0];
          delPic({ object: savefile }).then((res) => {
            // console.log(res, "delpic");
            if (res.data.code != 200) {
              return this.$message.error(res.data.msg);
            }
            this.fileList.forEach((item, idx) => {
              // console.log(item.url == file.url);
              if (item.url == file.url) {
                this.fileList.splice(idx, 1);
                let code = this.$store.state.indexIdentity;
                window.localStorage.setItem(
                  "sign_up_file_" +
                    window.localStorage.getItem("index-phone-all") +
                    code,
                  JSON.stringify(this.fileList)
                );
              }
            });
            this.ChangeImage(this.fileList);
            this.$message.success(res.data.msg);
          });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 滚动到固定地方
    scrollView(object) {
      for (let i in object) {
        if (!i) {
          continue;
        }
        let dom = this.$refs[i];
        // console.log(i, dom);
        if (!dom) {
          continue;
        }
        // 这里是针对遍历的情况（多个输入框），取值为数组
        if (Object.prototype.toString.call(dom) !== "[object Object]") {
          dom = dom[0];
        }
        // 第一种方法（包含动画效果）
        dom.$el.scrollIntoView({
          // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: "center",
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: "smooth",
        });
        break; // 因为我们只需要检测一项,所以就可以跳出循环了
      }
    },
    confirm() {
      this.$refs["referRef"].validate((valid, object) => {
        if (!valid) {
          this.scrollView(object);
          return false;
        }
        let _this = this;
        let obj = {};
        if (_this.configJson.name == 1) {
          obj.name = _this.referForm.name;
        }
        if (_this.configJson.gender == 1) {
          obj.gender = _this.referForm.gender;
        }
        if (_this.configJson.idCard == 1) {
          obj.id_card = _this.referForm.id_card;
        }
        if (_this.configJson.age == 1) {
          obj.age = _this.referForm.age;
        }
        if (_this.configJson.education == 1) {
          obj.education = _this.referForm.education;
        }
        if (_this.configJson.postAddress == 1) {
          obj.address = _this.referForm.address;
        }
        if (_this.configJson.country == 1) {
          obj.country = _this.referForm.country;
        }
        if (_this.configJson.nation == 1) {
          obj.nation = _this.referForm.nation;
        }
        if (_this.configJson.startSchoolDate == 1) {
          obj.start_school_date = _this.start_year;
        }
        if (_this.configJson.politicalStatus == 1) {
          obj.political_status = _this.referForm.political_status;
        }
        if (_this.configJson.phone == 1) {
          obj.phone = _this.referForm.phone;
        }
        if (_this.configJson.grade == 1) {
          obj.grade = _this.referForm.grade;
        }
        if (_this.configJson.unitandIdentity == 1) {
          obj.unit_and_identity = _this.referForm.unit_and_identity;
        }
        if (_this.configJson.email == 1) {
          obj.email = _this.referForm.email;
        }
        if (_this.configJson.jiguanCity == 1) {
          obj.jiguan_code = _this.referForm.jiguan_code;
        }
        if (_this.configJson.recommend == 1) {
          obj.recommend = _this.referForm.recommend;
        }
        if (_this.configJson.start_school_date == 1) {
          obj.start_school_date = _this.referForm.start_school_date;
        }

        if (_this.configJson.birthday == 1) {
          obj.birthday = _this.referForm.birthday;
        }

        if (_this.configJson.electSubjectSituation == 1) {
          obj.elect_subject_situation = _this.elect_subject_situation;
        }
        if (_this.configJson.pastMedicalHistory == 1) {
          obj.past_medical_history = _this.referForm.past_medical_history;
        }
        if (_this.configJson.noteOther == 1) {
          obj.note_other = _this.referForm.note_other;
        }
        if (_this.configJson.class == 1) {
          obj.class = _this.referForm.class;
        }
        if (_this.configJson.emergency == 1) {
          obj.emergency = _this.referForm.emergency;
        }
        if (_this.configJson.emergencyPhone == 1) {
          obj.emergency_phone = _this.referForm.emergency_phone;
        }
        //衣服尺码
        if (_this.configJson.clothesSize == 1) {
          obj.clothes_size = _this.referForm.clothes_size;
        }
        //自我介绍
        if (_this.configJson.introduceMyself == 1) {
          obj.introduce = _this.referForm.introduce;
        }
        if (_this.configJson.school == 1) {
          obj.school_province = _this.referForm.school_province;
          obj.school_city = _this.referForm.school_city;
          obj.school = _this.referForm.school;
        }
        if (_this.configJson.schoolContacts == 1) {
          obj.school_contacts = _this.referForm.school_contacts;
        }
        if (_this.configJson.schoolPhone == 1) {
          obj.school_phone = _this.referForm.school_phone;
        }
        //是否需要填写邀请码
        // if (_this.doubt_code == 1 && _this.code != '') {
        if (_this.configJson.code == 1) {
          obj.code = _this.referForm.code;
        }
        console.log(obj);
        setAccount(obj).then((res) => {
          console.log(res);
          if (res.data.code != 200) {
            if (res.data.code == 400068) {
              _this.$parent.statuss();
              return _this.$message.error(res.data.msg);
            }
            return _this.$message.error(res.data.msg);
          }
          if (res.data.code == 4000346 || res.data.code == 4000347) {
            _this.dialogVisible = true;
            _this.msg = res.data.msg;
            return;
          }
          _this.$emit("getstatus", res.data.status);
          _this.$message.success(res.data.msg);
          // _this.referForm = res.data.data;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "a/scss/btn";
@import "a/scss/SignUp/referInfo";
</style>
