<template>
  <!--底部-->
  <div class="invitation">
    <div class="iv_content flex">
      <div class="flex">
        <div class="code_titile">邀请码</div>
      </div>
      <div>
        <div class="code flex">
          <el-input
            :disabled="!inputFlag"
            :autofocus="true"
            ref="input0"
            @input="changeValue1"
            @focus="getFocus(0, $event)"
            v-model="invatation1"
          >
          </el-input>
          <el-input
            :disabled="!inputFlag"
            :autofocus="true"
            ref="input1"
            @input="changeValue1"
            @focus="getFocus(1, $event)"
            v-model="invatation2"
          >
          </el-input>
          <el-input
            :disabled="!inputFlag"
            :autofocus="true"
            ref="input2"
            @input="changeValue1"
            @focus="getFocus(2, $event)"
            v-model="invatation3"
          >
          </el-input>
          <el-input
            :disabled="!inputFlag"
            :autofocus="true"
            ref="input3"
            @input="changeValue1"
            @focus="getFocus(3, $event)"
            v-model="invatation4"
          ></el-input>
        </div>
        <!-- <div class="errorCode">该邀请码无效，请输入正确的邀请码。</div> -->
      </div>
    </div>

    <div class="tips flex">
      <div class="tipsContent">
        <div>备注：</div>
        <div>1.每个邀请码仅能绑定一个用户。</div>
        <div>2.如没有邀请码请直接点击【提交/跳过】跳过此步骤继续报名。</div>
      </div>
      <!-- <div>备注：</div>
      <div>1，每个邀请码仅能绑定一个用户。</div>
      <div>2，如没有邀请码请直接点击【下一步】跳过此步骤继续报名。</div> -->
      <!-- 备注：每个邀请码只能绑定一个用户，请谨慎使用。如没有邀请码请直接点击【下一步】跳过此步骤继续报名 -->
    </div>

    <div class="btn">
      <el-button @click="next()">提交/跳过</el-button>
    </div>
    <!--    多形式报名邀请码报错弹框   -->
   <cueDialog :dialogVisible="dialogVisible" :msg="msg"></cueDialog>
  </div>
</template>

<script>
  /* eslint-disable */
  import {setInviteCode} from "r/index/signUp";
  import cueDialog from "c/index/cueDialog";

  export default {
    name: "Invitation",
    props: {invitation_code: String},
    components:{cueDialog},
    data() {
      return {
        invatation1: "",
        invatation2: "",
        invatation3: "",
        invatation4: "",
        inputFlag: true,
        dialogVisible: false,
        msg: null,
      };
    },
    created() {
      if (this.invitation_code) {
        let a = this.invitation_code.split("-");
        this.invatation1 = a[0];
        this.invatation2 = a[1];
        this.invatation3 = a[2];
        this.invatation4 = a[3];
        this.inputFlag = false;
        // console.log(a[0])
      }
    },
    watch: {
      invatation1: function () {
        // /\n/g(删除制表符 /t的正则)
        this.invatation1 = this.invatation1
          .replace(/[\W]/g, "")
          // .replace(/\n/g,"")
          // .replace("/\n/g",'')
          .toUpperCase()
          .substring(0, 4);
      },
      invatation2: function () {
        this.invatation2 = this.invatation2
          .replace(/[\W]/g, "")
          .toUpperCase()
          .substring(0, 4);
      },
      invatation3: function () {
        this.invatation3 = this.invatation3
          .replace(/[\W]/g, "")
          .toUpperCase()
          .substring(0, 4);
      },
      invatation4: function () {
        this.invatation4 = this.invatation4
          .replace(/[\W]/g, "")
          .toUpperCase()
          .substring(0, 4);
      },
    },
    methods: {
      //第一个输入框
      changeValue1(e) {
        const code = e.trim();
        // console.log(code, "输入111");
        if (code.indexOf("-") !== -1) {
          //如果是复制过来的带'-'邀请码
          let copyCode = code.split("-");
          // console.log(copyCode, "输入222");
          this.invatation1 = copyCode[0];
          this.invatation2 = copyCode[1];
          this.invatation3 = copyCode[2];
          this.invatation4 = copyCode[3];
          this.$refs.input3.focus();
        } else if (code.length > 4) {
          this.changeValue(code.substring(0, 4));
        } else {
          this.changeValue(code);
        }
      },
      // 输入框
      changeValue(e) {
        if (e.length >= 4) {
          // console.log(e, "length");
          // console.log(this.currentIndex, "this.currentIndex");
          switch (this.currentIndex) {
            case 0:
              // console.log(this.text0.length);
              if (this.invatation1.length >= 4) {
                // this.$nextTick(() => {
                this.$refs.input1.focus();
                // })
              }
              break;
            case 1:
              if (this.invatation2.length >= 4) {
                this.$refs.input2.focus();
              }
              break;
            case 2:
              if (this.invatation3.length >= 4) {
                this.$refs.input3.focus();
              }
              break;
            case 3:
              if (this.invatation4.length >= 4) {
                this.$refs.input3.focus();
              }
              break;
            default:
              break;
          }
        } else if (e.length === 0) {
          switch (this.currentIndex) {
            case 0:
              this.$refs.input0.focus();
              break;
            case 1:
              this.$refs.input0.focus();
              break;
            case 2:
              this.$refs.input1.focus();
              break;
            case 3:
              this.$refs.input2.focus();
              break;
            default:
              break;
          }
        } else {
        }
      },
      getFocus(index, e) {
        this.currentIndex = index;
      },

      // 下一步
      async next() {
        // console.log(111);
        let code;
        if (
          this.invatation1 == "" &&
          this.invatation2 == "" &&
          this.invatation3 == "" &&
          this.invatation4 == ""
        ) {
          code =
            this.invatation1 +
            this.invatation2 +
            this.invatation3 +
            this.invatation4;
        } else {
          code =
            this.invatation1 +
            "-" +
            this.invatation2 +
            "-" +
            this.invatation3 +
            "-" +
            this.invatation4;
        }

        setInviteCode({code: code}).then((res) => {
          // console.log(res, "code");
          if (res.data.code !== 200) {
            return this.$message.error(res.data.msg);
          }
          if (res.data.code == 4000346 || res.data.code == 4000347) {
            this.dialogVisible = true;
            this.msg = res.data.msg
            return;
          }
          this.$emit("getstatus", res.data.status);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/btn";

  .flex {
    display: flex;
  }

  .invitation {
    .iv_content {
      justify-content: center;
      height: 44px;

      ::v-deep .el-input__inner {
        width: 110px;
        height: 38px;
        border-radius: 4px;
        border: 2px solid var(--color);
        margin-right: 15px;
      }

      .code_titile {
        height: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #1b1419;
        line-height: 48px;
        margin-right: 10px;
      }

      .errorCode {
        margin-top: 10px;
      }
    }

    .tips {
      font-size: 14px;
      font-weight: 500;
      // color: #a7a2a6;
      color: var(--color);
      line-height: 23px;
      margin: 30px 0;
      // text-align: center;
      flex-direction: column;
      align-items: center; /*垂直居中*/
      width: 100%;
      height: 100%;
    }

    .errorCode {
      font-size: 12px;
      font-weight: 400;
      color: #d7373f;
      line-height: 12px;
    }
  }


</style>
