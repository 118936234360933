<template>
  <!--底部-->
  <div class="confirm">
    <div class="title">{{ info.name }}报名须知</div>
    <el-form
      ref="referRef"
      :model="referForm"
      label-position="top"
      class="checkForm"
      :rules="referInfoRules"
    >
      <el-form-item v-if="is_multiform == 1"
                    label="活动形式"
                    prop="multiform"
                    class="multiform"
                    style="height: 100%"
                    :rules="
              is_multiform == 1 ? referInfoRules.multiform : [{ required: false }]
            "
      >
        <el-radio-group ref="multiform" v-model="referForm.multiform">
          <div v-for="item in multiform" :key="item.id">
            <el-radio :label="item.id.toString()">{{item.multiform_name}}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div v-html="info.exam_instructions"></div>
    <!-- <p>
      南开大学是教育部直属重点综合性大学，是敬爱的周恩来总理的母校。新中国成立以来，学校发展始终得到党和国家的亲切关怀。毛泽东主席题写校名、亲临视察；周恩来总理三回母校指导；邓小平同志会见数学大师陈省身，批示成立南开数学研究所；江泽民同志、胡锦涛同志先后视察南开。特别是党的十八大以来，习近平总书记多次对南开的发展给予肯定，并对相关工作回信和勉励，更在百年校庆之际亲临南开视察。
    </p>
    <p>
      南开大学是教育部直属重点综合性大学，是敬爱的周恩来总理的母校。新中国成立以来，学校发展始终得到党和国家的亲切关怀。毛泽东主席题写校名、亲临视察；周恩来总理三回母校指导；邓小平同志会见数学大师陈省身，批示成立南开数学研究所；江泽民同志、胡锦涛同志先后视察南开。特别是党的十八大以来，习近平总书记多次对南开的发展给予肯定，并对相关工作回信和勉励，更在百年校庆之际亲临南开视察。
    </p>
    <div class="line"></div>
    <p>
      南开大学是教育部直属重点综合性大学，是敬爱的周恩来总理的母校。新中国成立以来，学校发展始终得到党和国家的亲切关怀。毛泽东主席题写校名、亲临视察；
    </p>
    <div>
      <img src="../../../assets/img/signUp/computer.png" alt="" />
    </div> -->
    <div class="check">
      <el-checkbox v-model="checked">我已阅读并了解须知</el-checkbox>
    </div>
    <div class="btn">
      <el-button :disabled="!checked" @click="next()">下一步</el-button>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import Cookie from "js-cookie";
  import {setSignShould} from "r/index/signUp";
  import {getCam} from "r/index/login";

  export default {
    name: "Confirm",
    // props: { statusNum: [String, Number] }, //进度参数
    data() {
      return {
        checked: false,
        info: {},
        referForm: {multiform: null,},
        //是否开启多形式报名
        is_multiform: 0,
        multiform: [],
        referInfoRules: {
          multiform: [{required: true, message: '请选择活动形式', trigger: 'change'}],
        },
      }
    },
    created() {
      this.info = this.$store.state.info;
      const code = this.$store.state.indexIdentity;
      //是否开启多形式报名
      this.is_multiform = this.$store.state.isMultiform;
      if (this.is_multiform == 1) {
        this.multiform = JSON.parse(window.localStorage.getItem("multiform" + code));
      }
      let multiform_id = window.localStorage.getItem("multiform_id" + code);
      if (multiform_id && multiform_id !== null) {
        this.referForm.multiform = multiform_id;
      }
    },
    methods: {
      next() {
        if (this.referForm.multiform == 0 && this.is_multiform == 1) {
          return this.$message.error('请选择活动形式')
        } else {
          let _this = this;
          let obj = {};
          //是否选择活动形式
          obj.multiform_id = _this.referForm.multiform;
          setSignShould(obj).then((res) => {
            if (res.data.code !== 200) return this.$message.error(res.data.msg);
            this.$emit("getstatus", res.data.status);
            this.$message.success(res.data.msg);
          });
        }

      },
      getCams() {
        getCam(this.$store.state.indexIdentity).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.info = res.data;
        });
      }
      ,
    }
    ,
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/btn";

  .btn {
    padding-bottom: 34px !important;
  }

  .confirm {
    padding: 0 50px 60px;

    .line {
      border-bottom: 1px solid #d4d0d4;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #1b1419;
      line-height: 20px;
      text-align: center;
      margin: 0 0 24px 0;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #1b1419;
      line-height: 28px;
    }

    .check {
      text-align: center;
      margin-top: 42px;
    }

    ::v-deep .multiform .el-form-item__label {
      font-size: 15px !important;
    }

    ::v-deep .el-form-item {
      margin-bottom: 20px;
      height: 70px;

      .el-form-item__label {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #12141c;

      }

      &.no_label .el-form-item__label:before {
        content: "" !important;
      }

      .el-form-item__content {

        // 圆
        .el-radio-group {
          .el-radio {
            .el-radio__label {
              color: #656872;
            }

            .el-radio__input {
              .el-radio__inner {
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;

                &:hover {
                  border-color: var(--color);
                }
              }

              &.is-checked {
                .el-radio__inner {
                  background: #ffffff !important;
                  border-color: var(--color);

                  &::after {
                    width: 7.5px;
                    height: 7.5px;
                    background: var(--color) !important;
                  }
                }
              }
            }
          }
        }
      }


    }

    ::v-deep .el-checkbox__inner:hover {
      border-color: var(--color);
    }

    ::v-deep .el-checkbox__input.is-checked {
      .el-checkbox__inner {
        background-color: var(--color);
        border-color: var(--color);
      }

      + .el-checkbox__label {
        color: var(--color);
      }
    }
  }
</style>
