/* eslint-disable */
import {request} from '../network';
import store from '@/store';


// 报名初始化
export function getWxConfig(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'get',
    url: '/web/pay/getWxPreselectionConfig',
    params: data
  })
}

//订单状态检查跳转界面(成功-跳转到支付成功页面)
export function checkAsync(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/pay/async',
    params: data
  })
}

