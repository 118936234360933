var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"referInfo"},[_c('el-form',{ref:"referRef",staticClass:"checkForm",attrs:{"model":_vm.referForm,"rules":_vm.referInfoRules,"label-position":"top"}},[_c('div',{staticClass:"cont_title"},[_vm._v("个人信息")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.name == 1),expression:"configJson.name == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.name == 1 ? _vm.referInfoRules.name : [{ required: false }],"label":"姓名","prop":"name"}},[_c('el-input',{ref:"name",attrs:{"clearable":"","placeholder":"填写真实姓名","size":"small"},model:{value:(_vm.referForm.name),callback:function ($$v) {_vm.$set(_vm.referForm, "name", $$v)},expression:"referForm.name"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.gender == 1),expression:"configJson.gender == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.gender == 1
              ? _vm.referInfoRules.gender
              : [{ required: false }],"label":"性别","prop":"gender"}},[_c('el-radio-group',{ref:"gender",attrs:{"size":"small"},model:{value:(_vm.referForm.gender),callback:function ($$v) {_vm.$set(_vm.referForm, "gender", $$v)},expression:"referForm.gender"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("女")])],1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.age == 1),expression:"configJson.age == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.age == 1 ? _vm.referInfoRules.age : [{ required: false }],"label":"年龄","prop":"age"}},[_c('el-input',{ref:"age",attrs:{"clearable":"","placeholder":"填写年龄","size":"small"},model:{value:(_vm.referForm.age),callback:function ($$v) {_vm.$set(_vm.referForm, "age", $$v)},expression:"referForm.age"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.country == 1),expression:"configJson.country == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.country == 1
              ? _vm.referInfoRules.country
              : [{ required: false }],"label":"国籍","prop":"country"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"filterable":"","placeholder":"国籍"},model:{value:(_vm.referForm.country),callback:function ($$v) {_vm.$set(_vm.referForm, "country", $$v)},expression:"referForm.country"}},_vm._l((_vm.countryList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.label}})}),1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.idCard == 1),expression:"configJson.idCard == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.idCard == 1
              ? _vm.referInfoRules.idCard
              : [{ required: false }],"label":"身份证号","prop":"id_card"}},[_c('el-input',{ref:"id_card",attrs:{"placeholder":"填写真实身份证号","size":"small"},model:{value:(_vm.referForm.id_card),callback:function ($$v) {_vm.$set(_vm.referForm, "id_card", $$v)},expression:"referForm.id_card"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.phone == 1),expression:"configJson.phone == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.phone == 1
              ? _vm.referInfoRules.phone
              : [{ required: false }],"label":"手机号"}},[_c('el-input',{attrs:{"disabled":true,"placeholder":"请输入手机号","size":"small"},model:{value:(_vm.referForm.phone),callback:function ($$v) {_vm.$set(_vm.referForm, "phone", $$v)},expression:"referForm.phone"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.education == 1),expression:"configJson.education == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.education == 1
              ? _vm.referInfoRules.education
              : [{ required: false }],"label":"学历","prop":"education"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"请选择学历"},model:{value:(_vm.referForm.education),callback:function ($$v) {_vm.$set(_vm.referForm, "education", $$v)},expression:"referForm.education"}},_vm._l((_vm.EducationArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.unitandIdentity == 1),expression:"configJson.unitandIdentity == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.unitandIdentity == 1
              ? _vm.referInfoRules.unit_and_identity
              : [{ required: false }],"label":"单位名称及身份","prop":"unit_and_identity"}},[_c('el-input',{attrs:{"placeholder":"请输入单位名称及身份","size":"small"},model:{value:(_vm.referForm.unit_and_identity),callback:function ($$v) {_vm.$set(_vm.referForm, "unit_and_identity", $$v)},expression:"referForm.unit_and_identity"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.email == 1),expression:"configJson.email == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.email == 1
              ? _vm.referInfoRules.email
              : [{ required: false }],"label":"电子邮箱","prop":"email"}},[_c('el-input',{attrs:{"placeholder":"填写电子邮箱","size":"small"},model:{value:(_vm.referForm.email),callback:function ($$v) {_vm.$set(_vm.referForm, "email", $$v)},expression:"referForm.email"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.recommend == 1),expression:"configJson.recommend == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"label":"什么渠道了解的该项目(或推荐人是)?"}},[_c('el-input',{attrs:{"placeholder":"填写推荐人或渠道（选填）","size":"small"},model:{value:(_vm.referForm.recommend),callback:function ($$v) {_vm.$set(_vm.referForm, "recommend", $$v)},expression:"referForm.recommend"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.birthday == 1),expression:"configJson.birthday == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.birthday == 1
              ? _vm.referInfoRules.birthday
              : [{ required: false }],"label":"出生日期","prop":"birthday"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('el-select',{attrs:{"popper-append-to-body":false,"placeholder":"年"},model:{value:(_vm.bdYear),callback:function ($$v) {_vm.bdYear=$$v},expression:"bdYear"}},_vm._l((_vm.yearArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-select',{attrs:{"popper-append-to-body":false,"placeholder":"月"},model:{value:(_vm.bdMonth),callback:function ($$v) {_vm.bdMonth=$$v},expression:"bdMonth"}},_vm._l((_vm.monthArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1),_c('el-col',{attrs:{"span":8}},[_c('el-select',{ref:"birthday",attrs:{"popper-append-to-body":false,"placeholder":"日"},on:{"change":_vm.getTime},model:{value:(_vm.bdDay),callback:function ($$v) {_vm.bdDay=$$v},expression:"bdDay"}},_vm._l((_vm.dayArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.jiguanCity == 1),expression:"configJson.jiguanCity == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.jiguanCity == 1
              ? _vm.referInfoRules.jiguan_code
              : [{ required: false }],"label":"籍贯","prop":"jiguan_code"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-select',{attrs:{"popper-append-to-body":false,"placeholder":"省"},on:{"change":function($event){return _vm.changeProvince(1, $event)}},model:{value:(_vm.jgProvinceCode),callback:function ($$v) {_vm.jgProvinceCode=$$v},expression:"jgProvinceCode"}},_vm._l((_vm.selectProvince),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-select',{ref:"jiguan_code",attrs:{"popper-append-to-body":false,"placeholder":"市"},on:{"change":function($event){return _vm.getJiguan()}},model:{value:(_vm.jiguan_city),callback:function ($$v) {_vm.jiguan_city=$$v},expression:"jiguan_city"}},_vm._l((_vm.selectCityJiguan),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.nation == 1),expression:"configJson.nation == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.nation == 1
              ? _vm.referInfoRules.nation
              : [{ required: false }],"label":"民族","prop":"nation"}},[_c('el-input',{ref:"nation",attrs:{"clearable":"","placeholder":"填写民族","size":"small"},model:{value:(_vm.referForm.nation),callback:function ($$v) {_vm.$set(_vm.referForm, "nation", $$v)},expression:"referForm.nation"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.startSchoolDate == 1),expression:"configJson.startSchoolDate == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.startSchoolDate == 1
              ? _vm.referInfoRules.start_school_date
              : [{ required: false }],"label":"入学年份","prop":"start_school_date"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-select',{ref:"start_school_date",attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"年份"},on:{"change":function($event){return _vm.getyear()}},model:{value:(_vm.start_year),callback:function ($$v) {_vm.start_year=$$v},expression:"start_year"}},_vm._l((_vm.yearArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.politicalStatus == 1),expression:"configJson.politicalStatus == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.politicalStatus == 1
              ? _vm.referInfoRules.political_status
              : [{ required: false }],"label":"政治面貌","prop":"political_status"}},[_c('el-select',{ref:"political_status",attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"填写政治面貌"},model:{value:(_vm.referForm.political_status),callback:function ($$v) {_vm.$set(_vm.referForm, "political_status", $$v)},expression:"referForm.political_status"}},_vm._l((_vm.political_status),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.name}})}),1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.grade == 1),expression:"configJson.grade == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.grade == 1
              ? _vm.referInfoRules.grade
              : [{ required: false }],"label":"在读年级","prop":"grade"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"在读年级"},model:{value:(_vm.referForm.grade),callback:function ($$v) {_vm.$set(_vm.referForm, "grade", $$v)},expression:"referForm.grade"}},_vm._l((_vm.gradeArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.electSubjectSituation == 1),expression:"configJson.electSubjectSituation == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.electSubjectSituation == 1
              ? _vm.referInfoRules.subjectState
              : [{ required: false }],"label":"选科情况","prop":"subjectState"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"请选科"},on:{"change":_vm.changeSubjectState},model:{value:(_vm.referForm.subjectState),callback:function ($$v) {_vm.$set(_vm.referForm, "subjectState", $$v)},expression:"referForm.subjectState"}},_vm._l((_vm.electSubjectArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1),(_vm.configJson.electSubjectSituation == 1 && _vm.showSubject == 1)?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.showSubject == 1
              ? _vm.referInfoRules.chooseSubject
              : [{ required: false }],"label":"选科(最多选三科)","prop":"chooseSubject"}},[_c('el-select',{attrs:{"multiple-limit":3,"popper-append-to-body":false,"clearable":"","multiple":"","placeholder":"请选科"},on:{"change":_vm.changeSubjectList},model:{value:(_vm.referForm.chooseSubject),callback:function ($$v) {_vm.$set(_vm.referForm, "chooseSubject", $$v)},expression:"referForm.chooseSubject"}},_vm._l((_vm.SubjectArrs),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":item}})}),1)],1)],1):_vm._e(),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.pastMedicalHistory == 1),expression:"configJson.pastMedicalHistory == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":(_vm.configJson.pastMedicalHistory == 1 ? true : false) &&
            _vm.isOffline == true
              ? _vm.referInfoRules.pastMedicalHistory
              : [{ required: false }],"label":"既往病史或过敏史","prop":"past_medical_history"}},[_c('el-input',{ref:"code",attrs:{"clearable":"","placeholder":"填写既往病史或过敏史","size":"small"},model:{value:(_vm.referForm.past_medical_history),callback:function ($$v) {_vm.$set(_vm.referForm, "past_medical_history", $$v)},expression:"referForm.past_medical_history"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.noteOther == 1),expression:"configJson.noteOther == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":(_vm.configJson.noteOther == 1 ? true : false) && _vm.isOffline == true
              ? _vm.referInfoRules.noteOther
              : [{ required: false }],"label":"备注或其它","prop":"note_other"}},[_c('el-input',{ref:"code",attrs:{"clearable":"","placeholder":"填写备注或其它","size":"small"},model:{value:(_vm.referForm.note_other),callback:function ($$v) {_vm.$set(_vm.referForm, "note_other", $$v)},expression:"referForm.note_other"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.clothesSize == 1),expression:"configJson.clothesSize == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":(_vm.configJson.clothesSize == 1 ? true : false) && _vm.isOffline == true
              ? _vm.referInfoRules.clothesSize
              : [{ required: false }],"label":"衣服尺码","prop":"clothes_size"}},[_c('el-select',{ref:"clothes_size",attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"填写衣服尺码"},model:{value:(_vm.referForm.clothes_size),callback:function ($$v) {_vm.$set(_vm.referForm, "clothes_size", $$v)},expression:"referForm.clothes_size"}},_vm._l((_vm.clothesSize),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.name}})}),1)],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.code == 1),expression:"configJson.code == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"label":"邀请码","prop":"code"}},[_c('el-input',{ref:"code",attrs:{"disabled":_vm.isCode,"clearable":"","placeholder":"邀请码","size":"small"},model:{value:(_vm.referForm.code),callback:function ($$v) {_vm.$set(_vm.referForm, "code", $$v)},expression:"referForm.code"}})],1),_c('div',{staticClass:"tipsMsg"},[_vm._v("每个邀请码仅能绑定一个用户")])],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.introduceMyself == 1),expression:"configJson.introduceMyself == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"100%"},attrs:{"rules":_vm.configJson.introduceMyself == 1
              ? _vm.referInfoRules.introduceMyself
              : [{ required: false }],"label":"自我介绍","prop":"introduce"}},[_c('el-input',{ref:"code",attrs:{"rows":6,"maxlength":"200","placeholder":"自我介绍","show-word-limit":"","size":"small","type":"textarea"},on:{"change":_vm.changeIntroduce},model:{value:(_vm.referForm.introduce),callback:function ($$v) {_vm.$set(_vm.referForm, "introduce", $$v)},expression:"referForm.introduce"}})],1),_c('div',{staticClass:"tipsMsg"},[_vm._v(" 用清晰、简洁的语言介绍自己对科学和创新的兴趣、经验和动机，以及为什么希望参加“强国有我”复旦大学信息科学与工程学院青少年科学营 ")])],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.class == 1),expression:"configJson.class == 1"}],attrs:{"span":12}},[_c('el-form-item',{staticStyle:{"height":"73px"},attrs:{"rules":_vm.configJson.class == 1
              ? _vm.referInfoRules.class
              : [{ required: false }],"label":"班级","prop":"class"}},[_c('el-input',{ref:"class",attrs:{"clearable":"","placeholder":"班级","size":"small"},model:{value:(_vm.referForm.class),callback:function ($$v) {_vm.$set(_vm.referForm, "class", $$v)},expression:"referForm.class"}})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.emergency == 1 || _vm.configJson.emergencyPhone == 1),expression:"configJson.emergency == 1 || configJson.emergencyPhone == 1"}]},[_c('div',{staticClass:"cont_title"},[_vm._v("紧急联系人")]),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.emergency == 1),expression:"configJson.emergency == 1"}],attrs:{"span":12}},[_c('el-form-item',{attrs:{"rules":_vm.configJson.emergency == 1
                ? _vm.referInfoRules.emergency
                : [{ required: false }],"label":"紧急联系人","prop":"emergency"}},[_c('el-input',{ref:"code",attrs:{"clearable":"","placeholder":"填写紧急联系人","size":"small"},model:{value:(_vm.referForm.emergency),callback:function ($$v) {_vm.$set(_vm.referForm, "emergency", $$v)},expression:"referForm.emergency"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.emergencyPhone == 1),expression:"configJson.emergencyPhone == 1"}],attrs:{"span":12}},[_c('el-form-item',{attrs:{"rules":_vm.configJson.emergencyPhone == 1
                ? _vm.referInfoRules.emergency_phone
                : [{ required: false }],"label":"紧急联系人电话","prop":"emergency_phone"}},[_c('el-input',{ref:"code",attrs:{"clearable":"","placeholder":"填写紧急联系人电话","size":"small"},model:{value:(_vm.referForm.emergency_phone),callback:function ($$v) {_vm.$set(_vm.referForm, "emergency_phone", $$v)},expression:"referForm.emergency_phone"}})],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.configJson.school == 1 ||
        _vm.configJson.schoolContacts == 1 ||
        _vm.configJson.schoolPhone == 1
      ),expression:"\n        configJson.school == 1 ||\n        configJson.schoolContacts == 1 ||\n        configJson.schoolPhone == 1\n      "}]},[_c('div',{staticClass:"cont_title"},[_vm._v("学校信息")]),_c('div',{staticClass:"school"},[_c('el-row',{attrs:{"gutter":20}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.school == 1),expression:"configJson.school == 1"}],staticClass:"school_info"},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"rules":_vm.configJson.school == 1
                    ? _vm.referInfoRules.school_province
                    : [{ required: false }],"label":"就读中学","prop":"school_province"}},[_c('el-select',{ref:"school_province",attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"省"},on:{"change":function($event){return _vm.changeProvince(2, $event)}},model:{value:(_vm.referForm.school_province),callback:function ($$v) {_vm.$set(_vm.referForm, "school_province", $$v)},expression:"referForm.school_province"}},_vm._l((_vm.selectProvince),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{staticClass:"no_label",attrs:{"rules":_vm.configJson.school == 1
                    ? _vm.referInfoRules.school_city
                    : [{ required: false }],"label":" ","prop":"school_city"}},[_c('el-select',{ref:"school_city",attrs:{"popper-append-to-body":false,"clearable":"","placeholder":"市"},on:{"change":function($event){return _vm.changeCity($event)}},model:{value:(_vm.referForm.school_city),callback:function ($$v) {_vm.$set(_vm.referForm, "school_city", $$v)},expression:"referForm.school_city"}},_vm._l((_vm.selectCitySchool),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{staticClass:"no_label",attrs:{"rules":_vm.configJson.school == 1
                    ? _vm.referInfoRules.school
                    : [{ required: false }],"label":" ","prop":"school"}},[_c('el-select',{ref:"school",attrs:{"popper-append-to-body":false,"clearable":"","filterable":"","placeholder":"中学"},model:{value:(_vm.referForm.school),callback:function ($$v) {_vm.$set(_vm.referForm, "school", $$v)},expression:"referForm.school"}},_vm._l((_vm.selectSchoolList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.baseSchool == 1),expression:"$store.state.baseSchool == 1"}],staticClass:"tipsMsg"},[_vm._v(" 如果没有您所在的学校，请选填其他 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.baseSchool == 0),expression:"$store.state.baseSchool == 0"}],staticClass:"tipsMsg"},[_vm._v(" 如果没有您所在学校，请联系邮箱：service@campcenter.cn ")])],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.schoolContacts == 1),expression:"configJson.schoolContacts == 1"}],attrs:{"span":12}},[_c('el-form-item',{attrs:{"rules":_vm.configJson.schoolContacts == 1
                  ? _vm.referInfoRules.school_contacts
                  : [{ required: false }],"label":"中学联系人","prop":"school_contacts"}},[_c('el-input',{ref:"school_contacts",attrs:{"clearable":"","placeholder":"填写中学联系人","size":"small"},model:{value:(_vm.referForm.school_contacts),callback:function ($$v) {_vm.$set(_vm.referForm, "school_contacts", $$v)},expression:"referForm.school_contacts"}})],1)],1),_c('el-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.schoolPhone == 1),expression:"configJson.schoolPhone == 1"}],attrs:{"span":12}},[_c('el-form-item',{attrs:{"rules":_vm.configJson.schoolPhone == 1
                  ? _vm.referInfoRules.school_phone
                  : [{ required: false }],"label":"中学联系人电话（手机）","prop":"school_phone"}},[_c('el-input',{ref:"school_phone",attrs:{"clearable":"","placeholder":"填写中学联系人电话（手机）","size":"small"},model:{value:(_vm.referForm.school_phone),callback:function ($$v) {_vm.$set(_vm.referForm, "school_phone", $$v)},expression:"referForm.school_phone"}})],1)],1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.configJson.achievementPath == 1),expression:"configJson.achievementPath == 1"}]},[_c('div',{staticClass:"cont_title"},[_vm._v("学习成绩证明材料")]),_c('el-form-item',{staticClass:"bgPic",staticStyle:{"height":"100%"},attrs:{"label":"上传申请资料图片","prop":"backgroundPicture"}},[_c('div',{staticClass:"bgInfo"},[_vm._v(" 1、资料包括：近期校级考试的成绩单、在校期间获得证书等相关证明； ")]),_c('div',{staticClass:"bgInfo"},[_vm._v(" 2、请上传1-10张图片，支持JPG、JPEG、PNG等格式，每张图片不超过10M ")]),_c('div',{staticClass:"image_add"},[_c('div',{staticClass:"image_in"},[_c('el-upload',{ref:"imgUrl",class:{ hide: _vm.hideUpload },attrs:{"before-upload":_vm.beforeAvatarUpload,"disabled":_vm.isUploading || _vm.fileList.length >= 10,"file-list":_vm.fileList,"http-request":_vm.uploadPicture,"limit":10,"on-success":_vm.uploadSuccess,"accept":".jpg,.jpeg,.png","action":"#","list-type":"picture-card","name":"multipartFile"},scopedSlots:_vm._u([{key:"file",fn:function(ref){
                  var file = ref.file;
return _c('div',{},_vm._l((_vm.fileList),function(item,idx){return _c('div',{key:idx},[_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":file.url,"alt":""}}),_c('span',{staticClass:"el-upload-list__item-actions"},[_c('span',{staticClass:"el-upload-list__item-preview",on:{"click":function($event){return _vm.handlePictureCardPreview(file)}}},[_c('i',{staticClass:"el-icon-zoom-in"})]),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleDownload(file)}}},[_c('i',{staticClass:"el-icon-download"})]):_vm._e(),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()])])}),0)}}])},[_c('i',{staticClass:"el-icon-circle-plus",staticStyle:{"font-size":"44px"}})])],1),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"src":_vm.resultPicture,"alt":"","width":"100%"}})])],1)])],1),_c('div',{staticClass:"btn"},[_c('el-button',{on:{"click":_vm.confirm}},[_vm._v("提交资料")])],1)],1),_c('cueDialog',{attrs:{"dialogVisible":_vm.cueDialogVisible,"msg":_vm.msg}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }