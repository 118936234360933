<template>
  <div class="sucess">
    <div v-if="$store.state.human == 1" class="p_content">
      <div class="top">
        <div class="p_title">恭喜你！成功报名</div>
        <div>
          <img src="@/assets/img/signUp/offer.jpg" alt="" />
        </div>

        <div class="lips">
          <!-- <div>提醒事项：</div> -->
          <div>
            <span> 注</span>
            ：请及时完成入营通知书内的要求事项。
          </div>
        </div>
        <div class="flex btnList">
          <div class="btn">
            <el-button @click="handleDownload">下载课程通知书</el-button>
          </div>
          <div class="btn">
            <el-button @click="openAddress">输入邮寄地址</el-button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="p_content">
      <div class="top">
        <div class="p_title">恭喜你！成功报名</div>
        <div class="offer_img">
          <img src="@/assets/img/signUp/offer.jpg" alt="" />
        </div>

        <div class="lips">
          <!-- <div>提醒事项：</div> -->
          <div>
            <span> 注</span>
            ：请及时完成入营通知书内的要求事项。
          </div>
        </div>
        <div class="flex btnList">
          <div class="btn">
            <el-button @click="handleDownload">下载入营通知书</el-button>
          </div>
          <div class="btn">
            <el-button @click="openAddress">输入邮寄地址</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 收货地址 -->
    <delivery-address
      :dialogVisibleAddress="dialogVisibleAddress"
      @closeAddressDialog="closeAddressDialog"
    >
    </delivery-address>
  </div>
</template>

<script>
/* eslint-disable */
import { signUpInit } from "r/index/signUp";
import deliveryAddress from "@/components/index/SignUp/deliveryAddress.vue";

export default {
  name: "Sucess",
  components: {
    deliveryAddress,
  },
  data() {
    return {
      dialogVisibleAddress: false,
    };
  },
  created() {
    // console.log(123);
    // var url = location.search;
    // console.log(url);
  },
  methods: {
    handleDownload() {
      signUpInit({}).then((res) => {
        if (res.data.code != 200) {
          return this.$message.error(res.data.msg);
        }
        if (res.data.is_dowload == 1) {
          window.location.href = res.data.dowload_url;
        } else {
          return this.$message.error("营地下载证书还未开始");
        }
      });
    },
    openAddress() {
      this.dialogVisibleAddress = true;
    },
    // 子组件触发，关闭确认报名弹框
    closeAddressDialog(val) {
      console.log(val, "val");
      this.dialogVisibleAddress = val;
    },
  },
};
</script>

<style  lang="scss" scoped>
@import "a/scss/btn";
.btn {
  padding-bottom: 0 !important;
}
.sucess {
  padding: 0 56px 65px 56px;
  .p_content {
    .top {
      .p_title {
        font-size: 18px;
        font-weight: 500;
        color: #12141c;
        line-height: 18px;
        margin: 10px 0 30px 0;
      }
      div {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
      }
      .offer_img img {
        width: 500px;
      }
    }
    .btnList {
      justify-content: center;
      div {
        padding: 0 30px;
      }
    }
  }
  //  注意事项
  .lips {
    margin-top: 20px;
    div {
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      line-height: 21px;
    }
    span {
      color: red;
      font-weight: 600;
    }
  }
}
</style>
