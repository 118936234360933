<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    @close="cancelBtn()"
    @open="confirm()"
    title="微信支付"
    top="0"
    width="520px"
  >
    <div class="pay_code">
      <div v-show="isShowqr == 1">
        <!-- <div class="pay_time">支付剩余时间<span>15分30秒</span></div> -->
        <div class="money">￥{{ has_amount }}</div>
        <div class="img_qr">
          <div class="qrcode" ref="qrCodeUrl"></div>
        </div>
        <div>请使用<span>微信</span>扫一扫，扫描二维码支付</div>
        <div>
          付款码为动态码，仅支持通过本页面进行扫描付款操作，不支持另存图片后扫描支付。
        </div>
      </div>
      <div v-show="isShowqr == 2">
        <div class="info_2" style="color: #000; font-size: 18px">
          <i class="el-icon-warning"></i>{{ message }}
        </div>
      </div>
    </div>
    <span class="dialog-footer" slot="footer">
      <div class="btn">
        <el-button @click="updateCode()">刷新二维码</el-button>
        <!-- <el-button class="cancel" @click="cancelBtn()">取 消</el-button> -->
      </div>
    </span>
  </el-dialog>
</template>

<script>
  /* eslint-disable */
  import {checkAsync, getWxConfig} from "r/index/pay";
  import QRCode from "qrcodejs2";

  export default {
    name: "ConfirmSignUp",
    props: {
      confirmSignUpDialogFu: Boolean,
    },
    data() {
      return {
        dialogVisible: this.confirmSignUpDialogFu,
        message: "",
        isShowqr: 0, // 展示支付二维码
        token: "",
        websocket: null, //websocket连接
        lockReconnect: false, //是否真正建立连接
        timeout: 28 * 1000, //30秒一次心跳
        timeoutObj: null, //心跳心跳倒计时
        serverTimeoutObj: null, //心跳倒计时
        timeoutnum: null, //断开 重连倒计时
        has_amount: "",
        isSuccess: 0
      };
    },
    created() {
      this.token = window.localStorage.getItem("index-token-all");
      this.has_amount = window.localStorage.getItem(
        "has_amount" + this.$store.state.indexIdentity
      );
    },
    methods: {
      async confirm() {
        // console.log(SERVER_WS_URL);
        let {data: res} = await getWxConfig({
          pay_type: 3,
        });
        if (res.code !== 200) {
          if (res.code == 400801) {
            return this.cancelBtn();
          }
          this.isShowqr = 2;
          this.message = res.msg;
          return;
        }
        this.isShowqr = 1;
        this.createQrCode(res.code_url);
        //初始化 websocket 链接
        // this.initWebSocket();
        this.checkStatus(3);
      },
      updateCode() {
        this.confirm();
      },
      createQrCode(qrCode) {
        this.removeQr();
        let qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: qrCode, // 需要转换为二维码的内容
          width: 140,
          height: 140,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      },
      // 删除二维码img标签
      removeQr() {
        if (this.$refs.qrCodeUrl) {
          // 获取 父 标签下的所有子节点
          let pObjs = this.$refs.qrCodeUrl.childNodes;
          // console.log(pObjs);
          for (let i = pObjs.length - 1; i >= 0; i--) {
            // 一定要倒序，正序是删不干净的，可自行尝试
            this.$refs.qrCodeUrl.removeChild(pObjs[i]);
          }
        }
      },
      // 确认,关闭弹框，修改父组件的值
      cancelBtn() {
        // 关闭时，删除二维码img标签
        this.removeQr();
        this.dialogVisible = false;
        this.$emit("closeCFSUDialog", this.dialogVisible, this.isSuccess);
      },
      //订单状态检查跳转界面(成功-跳转到支付成功页面)
      checkStatus(type) {
        let order_no = window.localStorage.getItem(
          "order_no" + this.$store.state.indexIdentity
        )
        checkAsync({order_no: order_no, pay_type: type}).then((res) => {
          if (res.data.code != 200) {
            return this.reconnectCheckStatus()
          }
          //code=200,跳到支付成功页面
          this.isSuccess = 1
          this.$message.success(res.data.msg);
          this.cancelBtn()
        });
      },
      //重新连接
      reconnectCheckStatus() {
        let that = this;
        if (that.dialogVisible === false) {
          return;
        }
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum);
        that.timeoutnum = setTimeout(function () {
          //新连接
          that.checkStatus(3);
          that.lockReconnect = false;
        }, 2000);
      },
    },
    // 页面注销时候调用 避免连接错误
    // destroyed() {
    //   this.closeWebSocket();
    // },
    watch: {
      confirmSignUpDialogFu() {
        this.dialogVisible = this.confirmSignUpDialogFu;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    text-align: center;
    padding-bottom: 24px;
  }

  .el-button {
    width: 200px;
    height: 50px;
    background: var(--color);
    border-radius: 4px;
    opacity: 0.8;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    padding: 0;
  }

  .pay_code {
    text-align: center;

    .money {
      font-size: 20px;
      font-weight: 500;
      color: var(--color);
      line-height: 24px;
    }
  }

  .img_qr {
    width: 140px;
    height: 140px;
    padding: 10px;
    // border: 1px solid #000;
    margin: 0 auto;
  }
</style>
