<template>
  <div class="steps flex">
    <div class="step flex" v-for="(item, index) in bCList" :key="item.status">
      <div
        :class="
          'cricle' + ' ' + (status >= item.status ? 'speed-bg-color' : '')
        "
      >
        <span class="num">{{ index + 1 }}</span>
      </div>
      <div
        :class="
          'step_title' + ' ' + (status >= item.status ? 'speed-text-color' : '')
        "
      >
        {{ item.name }}
      </div>
      <div>
        <img
          src="../assets/img/signUp/left.png"
          alt=""
          v-if="item.status < 4"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "breadcrumb",
  props: { statusNum: [String, Number] }, //进度参数
  data() {
    return {
      status: this.statusNum,
      bCList: [],
    };
  },
  created() {
    let code = this.$store.state.indexIdentity;
    const doubt_code = window.localStorage.getItem("doubt_code" + code);
    const doubt_info = window.localStorage.getItem("doubt_info" + code);
    const doubt_check = window.localStorage.getItem("doubt_check" + code);
    // console.log(doubt_check, "doubt_check");
    const newArr = [
      {
        name: "确认报名",
        status: 0,
      },
      {
        name: "填写邀请码",
        status: 1,
      },
      {
        name: "填写报名资料",
        status: 2,
      },
      {
        name: "报名资料审核",
        status: 3,
      },
      {
        name: "缴费",
        status: 4,
      },
    ];
    let arr = [];
    // console.log(newArr, "newArr");
    for (let i = 0; i < newArr.length; i++) {
      const item = newArr[i];
      if (
        (doubt_code == 0 && item.status == 1) ||
        (doubt_info == 0 && item.status == 2) ||
        (doubt_check == 0 && item.status == 3)
      ) {
      } else {
        arr.push(item);
      }
    }
    this.bCList = arr;
  },
  watch: {
    statusNum(val) {
      // console.log(val, "statusNum");
      this.status = val;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}

.speed-bg-color {
  background-color: var(--color) !important;
}

.speed-text-color {
  color: var(--color) !important;
}

.steps {
  .step {
    align-items: center;
    .cricle {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #ffff;
      background: #d0ced0;
      border-radius: 15px;
      margin-right: 10px;
      font-size: 14px;
      font-family: PingFang SC;
    }

    .step_title {
      flex: 1;
      font-size: 14px;
      height: 20px;
      font-weight: 600;
      color: #d0ced0;
      line-height: 20px;
      font-family: PingFang SC;
    }

    img {
      margin: 0 15px 0 10px;
    }
  }
}
</style>
