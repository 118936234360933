<template>
  <el-dialog
    :visible.sync="dialogVisibleFU"
    width="30%"
    :before-close="handleClose">
    <div class="multiform">
      <img src="../../assets/img/cue.png"/>
      <span>{{msgFU}}</span>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: "cueDialog",
    props: {dialogVisible: Boolean , msg: String,},
    data() {
      return {
        dialogVisibleFU: this.dialogVisible,
        msgFU: this.msg,
      };
    },
    methods: {
      handleClose(done) {
        done();
      },
    },
    watch: {
      dialogVisible() {
        this.dialogVisibleFU = this.dialogVisible;
      },
      signTitle() {
        this.msgFU = this.msg;
      },

    }
  }
</script>

<style lang="scss" scoped>

  .multiform {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
      margin-bottom: 16px;
    }

  }

  ::v-deep .el-dialog__body {
    padding: 10px 30px 35px 30px;
  }
</style>
